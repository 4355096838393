@tailwind base;
@layer base {
  img {
    @apply inline-block;
  }
}
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

textarea:focus-visible,
input:focus-visible {
  outline: none;
}

.info-input {
  padding: 14px 20px;
  background: none;
  width: 100%;
  color: #121212;
}

.info-input:-webkit-input-placeholder {
  color: #9399a4;
}

.terms-container {
  padding-top: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.terms-container b,
.terms-container p,
.terms-container table {
  margin-bottom: 15px;
  display: inline-block;
}

.terms-container table {
  border-collapse: collapse;
}

.terms-container table td {
  vertical-align: top;
  padding-bottom: 5px;
  border: 1px solid #000;
}

div,
span {
  user-select: none;
}

.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.5) !important;
}

.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content {
  border-radius: 12px !important;
  border: none !important;
  inset: 0px !important;
  position: unset !important;
  padding: 0px !important;
  max-width: 100% !important;
}
